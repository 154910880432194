<app-top-header class="top-bar-container" [bgStyle]="'solid'" screenTitle="About Us"></app-top-header>
<div class="page-content m-8">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <!-- {{aboutUs}} -->
    
     <div class="page-side-logo">
            <img [src]="logo" alt="">
        </div>
        <div class="contact-details">
            <div class="contact-title"> {{ 'About Us' | translate }} </div>

         <div class="business-phone" [innerHTML]= "aboutUs|iknLocalized"></div> 
        </div>
</div>
<app-footer></app-footer>
