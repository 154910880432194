import { Component, OnInit } from "@angular/core";
import { ApiResult, LocalizedField, TruckAContactUs } from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  contactUs: TruckAContactUs;
  logo: string = environment.logo;
  isLoading = true;
  constructor(apiServce: ApiService, public utils: UtilsService ,public trans: TranslateService) {
    apiServce.viewContacttUs().subscribe((res) => {
      this.contactUs = res;
      this.isLoading = false; 
    });
  }

  ngOnInit() {}
}
