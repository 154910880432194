import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { TruckViewDto, TruckPic, TruckSBOptionsWithDetails } from '../models';
import { Cart } from '../cart';
import { AreaComponent } from '../area/area.component';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash'
import { CountrySelectorComponent } from '../country-selector/country-selector.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  cart: Cart;
  truck: TruckSBOptionsWithDetails;
  truckPics: TruckPic[];
  allowAboutUs: boolean = false;
  area: any;
  allowTerms = environment.allow_terms;
  hideSelectLabel: boolean = true;
  isOpen: boolean;
  androidLink?: string;
  iosLink?: string;
  allowPreOrder: boolean = environment.allow_pre_order
  isWorkingNow: boolean;
  topSlidersOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    dots: true,
    navSpeed: 700,
    rtl: this.translate.currentLang == 'ar' ? true : false,
    navText: [' '],
    responsive: {
      0: {
        items: 1

      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }
  allowShipping = environment.allow_shipping;
  defaultCountry = environment.default_country;
  showArea = environment.show_area;
  country: any;
  governate: any;
  isLoading: boolean = false;

  @ViewChild(AreaComponent, { static: true }) areaSelector:
    AreaComponent;
  @ViewChild(CountrySelectorComponent, { static: true }) countrySelector: CountrySelectorComponent;
  private redirectTo: string = null;
  constructor(private api: ApiService, public utils: UtilsService,
    public srvCart: CartService,
    private router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute) {
    this.cart = srvCart.getCart();
    this.route.params.subscribe(p => {
      this.redirectTo = p['redirect'];
    })
    this.area = this.cart.orderInfo.pickupAddress.areaName;
    this.country = this.cart.orderInfo.pickupAddress.countryName;
    this.governate = this.cart.orderInfo.pickupAddress.governateName;
    this.allowShipping = this.cart.$truck && this.cart.$truck.allowShippingOutsideCountry;
   
  }

  ngOnInit() {
    this.isLoading = true;
    //force to go to first screen incase
    if (((this.area && !this.allowShipping)
      || (this.allowShipping && this.country && (this.area || this.governate)))
      && this.utils.isBackTriggered) {
      //$('.page-side').removeClass('sm-hidden')
      this.router.navigateByUrl('/');
    }
    this.api.viewSBOptionsDetails().subscribe(d => {
      this.truck = d;
      if (d.simpleBrokerOptions) {
        this.androidLink = d.simpleBrokerOptions.androidAppLink;
        this.iosLink = d.simpleBrokerOptions.iosAppLink;
      }
      this.allowPreOrder = d.enablePreOrder;

      if (d.pics && d.pics.length > 0)
        this.truckPics = _.filter(d.pics, c => c.showOnSlider == true);
      else
        this.truckPics = [];
      this.allowAboutUs = d.allowAboutUs;
      this.isOpen =
        this.utils.isNowBetween(d.operatingFrom, d.operatingTo);
      this.isWorkingNow = this.isOpen;
      if (!this.utils.isNavigationAllowed(d)) {
        this.isOpen = false;
        this.allowPreOrder = false;
        this.isWorkingNow = false;
      } else if (this.allowPreOrder) {
        this.isOpen = true;
      }

      // if(this.allowPreOrder)
      //   this.isOpen = true;
      this.utils.fbTrack('PageView');
      this.utils.snapchatTrack('PAGE_VIEW');

      setTimeout(() => $('.page-side').addClass('sm-hidden'));

    });

    this.api.viewTruck().subscribe(t => {
      this.isLoading = false;
      this.allowShipping = t.truck.allowShippingOutsideCountry;
      if (this.allowShipping && !this.cart.orderInfo.pickupAddress.countryId)
        this.cart.orderInfo.pickupAddress.countryId = this.defaultCountry;
      if (this.cart.orderInfo.pickupAddress.countryId && this.cart.orderInfo.pickupAddress.countryId != environment.default_country)
        this.showArea = false;
      else
        this.showArea = environment.show_area;
    });
  }

  openArea() {
    this.areaSelector.selectArea(() => {
      if (!this.utils.isNavigationAllowed(this.truck))
        return;
      if (this.truck.hasMultipleBranches) {
        //TODO: get branch here to know its state ?!!
        this._redirect();
      } else {
        this._redirect();
      }

    });
  }
  private _redirect() {
    if (this.redirectTo) {
      //$('.page-side').addClass('sm-hidden')
      this.router.navigateByUrl(this.redirectTo);
    }
    else {
      //$('.page-side').removeClass('sm-hidden')
      this.router.navigateByUrl('/');
    }
  }
  ngOnDestroy() {
    //$('.page-side').removeClass('sm-hidden')
  }

  navigateToUrl(pic) {
    if (pic.linkedItem)
      this.router.navigate(['/item/' + pic.linkedItem.id]);
    else if (pic.linkedCategory)
      this.router.navigate(['/categories/' + pic.linkedCategory.id + '/items']);
    else if (pic.linkedUrl)
      window.location.href = pic.linkedUrl;
  }

  openCountry() {
    this.countrySelector.selectCountry(() => {
      this.country = this.cart.orderInfo.pickupAddress.countryName;
    });
  }

  openCity() {
    this.countrySelector.selectCity(() => {
      if (!this.utils.isNavigationAllowed(this.truck))
        return;
      this._redirect();
    });
  }

  onCountryChange(countryId) {
    if (countryId != environment.default_country)
      this.showArea = false;
    else
      this.showArea = environment.show_area;

    //open another popup auto
    if (this.showArea)
      this.openArea();
    else {
      if (this.country != this.cart.orderInfo.pickupAddress.countryName)
        this.governate = undefined;
      setTimeout(() => this.openCity(), 500);
    }
  }
}
