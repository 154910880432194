<app-top-header class="top-bar-container" [bgStyle]="'solid'" screenTitle="Contact Us"></app-top-header>

<div class="page-content m-8">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>

    <!-- Logo Section -->
    <div class="page-side-logo">
        <img [src]="logo" alt="Logo">
    </div>

    <!-- Contact Us Content -->
    <div class="contact-details">
        <div class="contact-title"> {{ 'Contact Us' | translate }} </div>
        <div class="business-phone"> {{ 'Business phone number' | translate }} </div>
        <div class="contact-phone">
            <!-- Replacing phone icon with the SVG file -->
            <img class="phone-icon" src="assets/images/Icon akar-phone.svg" alt="Phone Icon"> +965 {{contactUs?.supportPhone}}
        </div>

        <!-- Business Address Section -->
        <div class="business-address">
            <div class="address-title"> {{ 'Business Address' | translate }} </div>
            <div class="contact-phone">
                <!-- Replacing location icon with the SVG file -->
                <img class="location-icon-img" src="assets/images/Icon akar-location.svg" alt="Location Icon"> {{trans?.currentLang == 'en'? contactUs?.address?.en : contactUs?.address?.ar}}
            </div>
            
        </div>
    </div>
</div>

<app-footer></app-footer>
