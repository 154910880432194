import { Component, OnInit } from "@angular/core";
import { ApiResult, LocalizedField } from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  aboutUs: LocalizedField;
  logo: string = environment.logo;
  isLoading = true;
  constructor(apiServce: ApiService, public utils: UtilsService) {
    debugger
    apiServce.viewAboutUs().subscribe((res) => {
      this.aboutUs = res.aboutUs;
      this.isLoading = false;
    });
  }

  ngOnInit() {}
}
