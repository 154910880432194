
<div class="page-heading">
    <div class="heading-l side-link">
        <div class=" text-center about-txt font-bold" *ngIf="allowAboutUs">
            <a [routerLink]="['/about-us']">{{'About Us' | translate}}</a>
        </div>
    </div>
    <div class="heading-l heading-c">
        <div class="title font-extraBold">
            <img class="logo-small" [src]="'/assets/images/logo-small.png'" alt="">
        </div>
    </div>
    <div class="heading-r side-link">
        <div class="text-center track-title font-bold">
            <a [routerLink]="['/track-order']">{{'Track Your Order ID' | translate}}</a>
        </div>
    </div>
</div>
<div class="page-content">
    <div class="product-block" *ngIf="truck">
      <div class="overlay-pic"  *ngIf="truck && !isOpen">
        <img src="{{'close_img' | translate}}" />
      </div>
        <div class="product-img">
           
            <owl-carousel-o [options]="topSlidersOptions" class="carousel slide">
              <ng-template carouselSlide *ngFor="let pic of truckPics">
                <img [src]="pic.pic || '/assets/images/logo.png'" alt="" (click)="navigateToUrl(pic)"
                class="d-block w-100 carousel-item" />
              </ng-template>
            </owl-carousel-o>


        </div>
    </div>
    <div class="product-info">
        <div iknLoader [isActive]='isLoading' class="loader-container"></div>
        <div class="wrap font-extraBold" *ngIf="truck && !isLoading">
          <div class="text-center">
            <div class="preorder-img" *ngIf="allowPreOrder && !isWorkingNow"><img src="{{'preorder_img' | translate}}"></div>
            <span style="font-size:18px;line-height: 20px; border-bottom:1px solid black;">{{'Choose Your Delivery Location' | translate}}</span>
          </div>
          <button type="button" class="search" (click)="openCountry()" *ngIf="allowShipping">
            <div class="search_button bg-primary"><i class="fa fa-map-marker"></i></div>
            <span class="search_term text-right" *ngIf="!country">{{'Choose your country' | translate}}</span>
            <span class="search_term text-right" *ngIf="country">{{country | iknLocalized}}</span>
          </button>
          <button type="button" class="search" (click)="openCity()" *ngIf="allowShipping && !showArea && country">
            <div class="search_button bg-primary"><i class="fa fa-map-marker"></i></div>
            <span class="search_term text-right" *ngIf="!governate">{{'Choose your city' | translate}}</span>
            <span class="search_term text-right" *ngIf="governate">{{governate | iknLocalized}}</span>
          </button>
          <button type="button" class="search" (click)="openArea()" *ngIf="!allowShipping || (showArea && country)">
                <div class="search_button bg-primary"><i class="fa fa-map-marker"></i></div>
                <span class="search_term text-right" *ngIf="!area">{{'Choose your location' | translate}}</span>
                <span class="search_term text-right" *ngIf="area">{{area | iknLocalized}}</span>
            </button>
          </div>
           <!--Terms & Conditions-->
           <div class="m-t-20"></div>
           <div *ngIf="iosLink || androidLink" style="display:flex;justify-content: center;align-items: center;margin-bottom: 5px;">
            <div *ngIf="iosLink" style="text-align: center;">
              <a [href]="iosLink" target="_blank"><img  src="../../assets/images/app-store-badge.png" style="width:250px;padding-left:8px" /></a>
            </div>
            <div *ngIf="androidLink" style="text-align: center;">
              <a [href]="androidLink" target="_blank"><img src="../../assets/images/google-play-badge.png" style="width:300px" /></a>
            </div>
          </div>
           <div class="font-extraBold wrap page-btm" *ngIf="allowTerms">
              <span class="text-link color-primary terms pull-left"
               [routerLink]="['/terms']">{{'Terms & Conditions' | translate}}</span>
               <!-- <a *ngIf="whatsAppLink" [href]="whatsAppLink" class="whats pull-heading-right" target="_blank">
                    <i class="fa fa-whatsapp whats-icon"></i>
                </a> -->
                <a *ngIf=" truck?.supportPhone" href="https://api.whatsapp.com/send?phone=965{{truck?.supportPhone}}" class="whats pull-heading-right" target="_blank">
                  <i class="fa fa-whatsapp whats-icon"></i>
              </a>
           </div>
        </div>
    </div>
<app-area [(areaObj)]="area" [(hideSelectLabel)]="hideSelectLabel"></app-area>
<app-country-selector (countryChange)="onCountryChange($event)"></app-country-selector>
<app-footer></app-footer>
